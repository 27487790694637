import { Suspense, lazy, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Routes, useNavigate, useRoutes } from 'react-router';

import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from './layouts/BaseLayout';

import SuspenseLoader from './components/SuspenseLoader';
import ManageUsers from './content/SuperAdmin/ManageUsers';
import EditUser from './content/SuperAdmin/EditUser';
import ManageCustomers from './content/SystemAdmin/ManageUsers';
import ManageSuppliers from './content/SystemAdmin/ManageSuppliers';
import Login from './content/Auth/Login';
import ForgotPassword from './content/Auth/Forgot Password';
import Register from './content/Auth/Register';
import { useSelector } from 'react-redux';
import ResetPassword from './content/Auth/Reset Password';
import EditCustomer from './content/SystemAdmin/ManageUsers/SingleCustomer';
import ManageSalesCustomers from './content/SalesRep/ManageCustomers';
import DuoCallBack from './DuoCallBack';
import CustomerPortalBulkOrder from './content/CustomerPortal/BulkOrder';
import BarCodeScanner from './content/CustomerPortal/BarcodeScanner';
import CustomerPortalBlanketPo from './content/CustomerPortal/BlanketPo';
import CustomerPortalForecast from './content/CustomerPortal/ForecastConfig';
import CustomerPortalEditParts from './content/CustomerPortal/EditParts';
import CustomerPortalMain from './content/CustomerPortal/Product';

import ProductCart from './content/CustomerPortal/Product/Cart';
import ProductDetail from './content/CustomerPortal/Product/ProductDetail';

import OrderHistory from './content/CustomerPortal/Product/Table/orderHistory';
import NoPermissions from './content/pages/Status/NoPermissions';
import CompareForecast from './content/SystemAdmin/ManageUsers/SingleCustomer/CustomerTabs/ForecastOrderConfig/CompareForecast';
import OverallDashboard from './content/Dashboard/Overall';
import Dashboard from './content/Dashboard';

import SupplierPortalMain from './content/SupplierPortal/POSalesView';
import EditSupplier from './content/SystemAdmin/ManageSuppliers/SingleSupplier';
import ManageRoles from './content/SuperAdmin/ManageRoles';
import FeatureList from './content/SystemAdmin/ManageUsers/SingleCustomer/CustomerTabs/CustomerInformation/FeatureList';

import RawMaterial from './content/DepletionAnalysis/RawMaterial';
import FinishedGood from './content/DepletionAnalysis/FinishedGood';

import RawMaterialList from './content/DepletionAnalysis/RawMaterial/Table';

import RawMaterialView from './content/DepletionAnalysis/RawMaterial/View';
import POChangeRequest from './content/SupplierPortal/BuyerView';

import BlanketPOView from './content/OpenBlanketPO/BlanketPOView';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// manage-userss.

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);

const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const AppRoutes = () => {
  const authUser = useSelector((state) => state?.auth?.authUserData);
  const customerData = useSelector((state) => state?.customers?.customerData);
  const permissions = useSelector((state) => state?.auth?.token?.authorities);
  const supplierData = useSelector((state) => state?.suppliers?.supplierData);
  const navigate = useNavigate();

  const hasPermission = (permission) => {
    return permissions?.permissions?.some(
      (userPermission) => userPermission === permission
    );
  };

  if (!authUser) {
    return useRoutes([
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '/',
            element: <Navigate to="auth" replace />
          }
        ]
      },
      {
        path: 'auth',
        element: <BaseLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="login" replace />
          },

          {
            path: 'login',
            element: <Login />
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />
          },
          {
            path: 'reset-password',
            element: <ResetPassword />
          },
          {
            path: 'register',
            element: <Register />
          },
          {
            path: 'duoCallback',
            element: <DuoCallBack />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="auth" replace />
      }
    ]);
  } else {
    return useRoutes([
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '/',
            element: <Navigate to="manage-users" replace />
          },

          {
            path: 'overview',
            element: <Navigate to="/" replace />
          },
          {
            path: 'status',
            children: [
              {
                path: '',
                element: <Navigate to="404" replace />
              },
              {
                path: '404',
                element: <Status404 />
              },
              {
                path: '500',
                element: <Status500 />
              },
              {
                path: 'maintenance',
                element: <StatusMaintenance />
              }
            ]
          },
          {
            path: '*',
            element: <Status404 />
          },
          {
            path: '/raw-material-depletion',
            element: <RawMaterial /> 
          },
          {
            path: '/blanket-po-view',
            element: <BlanketPOView /> 
          }
        ]
      },
      {
        path: '/',
        element: <SidebarLayout />,
        children: [
          {
            path: '/',
            element: <Navigate to="manage-users" replace />
          },

          {
            path: 'manage-roles',
            element: hasPermission('ManageRoles') ? (
              <ManageRoles />
            ) : (
              <NoPermissions />
            )
          },
          {
            path: 'manage-features',
            element: hasPermission('ManageFeatures') ? (
              <FeatureList />
            ) : (
              <NoPermissions />
            )
          },

          {
            path: 'manage-users',
            element: hasPermission('ManageUsers') ? (
              <ManageUsers />
            ) : (
              <NoPermissions />
            )
          },

          {
            path: 'manage-customers',
            element: hasPermission('ManageCustomers') ? (
              <ManageCustomers />
            ) : (
              <NoPermissions />
            )
          },
          {
            path: 'manage-suppliers',
            element: hasPermission('ManageSuppliers') ? (
              <ManageSuppliers />
            ) : (
              <NoPermissions />
            )
          },

          {
            path: 'po-change-request',
            element: <POChangeRequest />
          },
          {
            path: 'manage-sales-customers',
            element: hasPermission('ManageSalesCustomers') ? (
              <ManageSalesCustomers />
            ) : (
              <NoPermissions />
            )
          },
          {
            path: 'compare-forecast',
            element: hasPermission('CompareForecast') ? (
              <CompareForecast />
            ) : (
              <NoPermissions />
            )
          },

          {
            path: 'portal',
            element: (
              <Dashboard />
            ) 
          },

          {
            path: 'home',
            element: <StatusMaintenance />
          },
          {
            path: 'customers',
            element: <StatusMaintenance />
          },
          {
            path: 'under-development',
            element: <StatusMaintenance />
          }
        ]
      },
      {
        path: 'raw-material-list',
        element: <SidebarLayout />,
        children: [
          {
            path: 'user',
            element: <Navigate to=":id" replace />
          },          
          {
            path: `:id`,
            element: <RawMaterialList />
          }
        ]
      },
      {
        path: 'raw-material-view',
        element: <SidebarLayout />,
        children: [
          {
            path: 'user',
            element: <Navigate to=":id" replace />
          },          
          {
            path: `:id`,
            element: <RawMaterialView />
          },
          {
            path: `:id/:customerNumber`,
            element: <RawMaterialView />
          }
        ]
      },
      {
        path: 'finished-good-depletion',
        element: <SidebarLayout />,
        children: [
          {
            path: 'user',
            element: <Navigate to=":id" replace />
          },          
          {
            path: `:id`,
            element: <FinishedGood />
          }
        ]

      },
      {
        path: 'raw-material-depletion',
        element: <SidebarLayout />,
        children: [
          {
            path: 'user',
            element: <Navigate to=":id" replace />
          },          
          {
            path: `:id`,
            element: <RawMaterial />
          }
        ]
      },
      {
        path: 'manage-users',
        element: <SidebarLayout />,
        children: [
          {
            path: 'user',
            element: <Navigate to=":id" replace />
          },

          {
            path: ':id',
            element: hasPermission('ManageUsers') ? (
              <EditUser />
            ) : (
              <NoPermissions />
            )
          }
        ]
      },
      {
        path: 'manage-customers',
        element: <SidebarLayout />,
        children: [
          {
            path: 'user',
            element: <Navigate to=":id" replace />
          },

          {
            path: ':id',
            element: hasPermission('ManageCustomers') ? (
              <EditCustomer />
            ) : (
              <NoPermissions />
            )
          }
        ]
      },
      {
        path: 'manage-suppliers',
        element: <SidebarLayout />,
        children: [
          {
            path: 'user',
            element: <Navigate to=":id" replace />
          },

          {
            path: ':id',
            element: hasPermission('ManageSuppliers') ? (
              <EditSupplier />
            ) : (
              <NoPermissions />
            )
          }
        ]
      },
      {
        path: 'auth',
        element: <BaseLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="login" replace />
          },

          {
            path: 'login',
            element: <Login />
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />
          },
          {
            path: 'reset-password',
            element: <ResetPassword />
          },
          {
            path: 'register',
            element: <Register />
          }
        ]
      },
      {
        path: 'customer-portal',
        element: <SidebarLayout />,
        children: [
          {
            path: 'user',
            element: <Navigate to=":id" replace />
          },

          customerData?.features?.find((d) => d?.code === 'BF') !== null
            ? {
                path: ':id/products',
                element: <CustomerPortalMain />
              }
            : {
                path: ':id/products',
                element: <NoPermissions />
              },

          {
            path: ':id/products',
            element: hasPermission('CustomerPortal') ? (
              <CustomerPortalMain />
            ) : (
              <NoPermissions />
            )
          },

          authUser?.features?.find((d) => d?.code === 'BO') !== null
            ? {
                path: ':id/bulkOrder',
                element: <CustomerPortalBulkOrder />
              }
            : {
                path: ':id/bulkOrder',
                element: <NoPermissions />
              },

          {
            path: ':id/bulkOrder',
            element: hasPermission('CustomerPortal') ? (
              <CustomerPortalBulkOrder />
            ) : (
              <NoPermissions />
            )
          },

          authUser?.features?.find((d) => d?.featureCode === 'BPO') !== null
            ? {
                path: ':id/blanketPo',
                element: <CustomerPortalBlanketPo />
              }
            : {
                path: ':id/blanketPo',
                element: <NoPermissions />
              },
              authUser?.features?.find((d) => d?.featureCode === 'BPO') !== null
            ? {
                path: ':id/blanket-po-view',
                element: <BlanketPOView />
              }
            : {
                path: ':id/blanket-po-view',
                element: <NoPermissions />
              },
          {
            path: ':id/blanketPo',
            element: hasPermission('CustomerPortal') ? (
              <CustomerPortalBlanketPo />
            ) : (
              <NoPermissions />
            )
          },

          authUser?.features?.find((d) => d?.featureCode === 'BCS') !== null
            ? {
                path: ':id/BarCodeScanner',
                element: <BarCodeScanner />
              }
            : {
                path: ':id/BarCodeScanner',
                element: <NoPermissions />
              },

          {
            path: ':id/BarCodeScanner',
            element: hasPermission('CustomerPortal') ? (
              <BarCodeScanner />
            ) : (
              <NoPermissions />
            )
          },

          {
            path: ':id/EditParts',
            element: hasPermission('CustomerPortal') ? (
              <CustomerPortalEditParts />
            ) : (
              <NoPermissions />
            )
          },
          authUser?.features?.find((d) => d?.featureCode === 'FC') !== null
            ? {
                path: ':id/forecast',
                element: <CustomerPortalForecast />
              }
            : {
                path: ':id/forecast',
                element: <NoPermissions />
              },

          {
            path: ':id/forecast',
            element: hasPermission('CustomerPortal') ? (
              <CustomerPortalForecast />
            ) : (
              <NoPermissions />
            )
          },

          {
            path: ':id/cart',
            element: hasPermission('CustomerPortal') ? (
              <ProductCart />
            ) : (
              <NoPermissions />
            )
          },
          {
            path: `:id/product/:partNumber`,
            element: hasPermission('CustomerPortal') ? (
              <ProductDetail />
            ) : (
              <NoPermissions />
            )
          },
          {
            path: `:id/orderHistory`,
            element: hasPermission('CustomerPortal') ? (
              <OrderHistory />
            ) : (
              <NoPermissions />
            )
          }
        ]
      },
      {
        path: 'supplier-portal',
        element: <SidebarLayout />,
        children: [
          {
            path: 'supplier',
            element: <Navigate to=":id" replace />
          },
          {
            path: `:id/poview`,
            element: <SupplierPortalMain />
          }
        ]
      }
    ]);
  }
};

export default AppRoutes;
